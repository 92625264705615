import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { SERVER_CONFIG } from '../utils/config';
function UserProfileImage({ onUpload, onRemove, path }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const imageRef = useRef(null);
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setIsLoaded(true);
    }
  }, []);
  const [imagePath, setImagePath] = useState(path || '');
  console.log('imagePath', imagePath);
  return (
    <div className='flex justify-center mb-8 -mt-8 border-2 rounded-full shadow-sm'>
      <div className={`flex justify-center items-center flex-col relative`}>
        <div
          style={{
            height: imagePath ? 120 : 90,
            width: imagePath ? 120 : 90,
            borderRadius: imagePath ? 60 : 0,
            overflow: 'hidden',
          }}
        >
          {imagePath ? (
            <img
              ref={imageRef}
              style={{ height: '100%', width: '100%' }}
              src={`${SERVER_CONFIG.SERVER_IP}/${imagePath}`}
              alt='Profile'
            />
          ) : (
            <div className='border-gray-500 border-2 rounded-full p-1 h-full w-full'>
              <IconButton
                style={{ height: '100%', width: '100%' }}
                className='border borderColor'
              >
                <Icon fontSize='large'>
                  <PersonIcon fontSize='large' />
                </Icon>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfileImage;
