import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RegistrationForm from '../components/RegistrationForm';
import DrawerSidebar from '../components/DrawerSidebar';
import { SearchInputField } from '../components/TextInputField';
import axios from 'axios';
import { SERVER_CONFIG, isAuthenticated } from '../utils/config';

function PendingMembers() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [modalData, setModalData] = useState({});

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    handleScrollEvent();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, limit]);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(
      `${SERVER_CONFIG.SERVER_IP}/api/v1/members?status=PENDING&page=${page}&limit=${limit}&document=true`
    );
    const newData = await response.json();
    setTimeout(() => {
      setUserData((prevData) => [...prevData, ...newData.rows]);
      setLoading(false);
    }, 1000);
  };

  const handleScrollEvent = () => {
    window.addEventListener('scroll', handleScroll);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.black,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getListOfMembers = async () => {
    try {
      const response = await axios.get(
        `${SERVER_CONFIG.SERVER_IP}/api/v1/members?status=pending&document=true`,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  console.log('userData', userData);

  return (
    <div>
      <DrawerSidebar>
        <div style={{ width: 500 }}>
          <SearchInputField
            text={''}
            autoFocus={false}
            placeholder={'Search'}
            onSearchClick={(searchKey) => {
              console.log('searchKey', searchKey);
            }}
            handleChange={(e) => {
              console.log('e.target.value', e.target.value);
            }}
          />
        </div>
        <div className='my-10'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Date of birth</StyledTableCell>
                  <StyledTableCell>Gender</StyledTableCell>
                  <StyledTableCell>Phone number</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((item) => (
                  <StyledTableRow
                    onClick={() => {
                      setModalOpen(true);
                      setModalData(item);
                    }}
                    key={item?.name}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {item?.name}
                    </StyledTableCell>
                    <StyledTableCell>{item?.dateOfBirth}</StyledTableCell>
                    <StyledTableCell>{item?.sex}</StyledTableCell>
                    <StyledTableCell>{item?.cellNo}</StyledTableCell>
                    <StyledTableCell>{item?.status}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {!loading && userData === 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>
                      No Records found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {loading && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>Loading...</StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <RegistrationForm
            data={modalData}
            onOpen={modalOpen}
            onClose={() => setModalOpen(false)}
          />
        </div>
      </DrawerSidebar>
    </div>
  );
}

export default PendingMembers;
