import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioButtonsGroup = ({
  selectedValue,
  onSelection,
  data,
  title,
  disableAll,
  ...rest
}) => {
  const handleChange = (event) => {
    if (!disableAll) {
      onSelection(event.target.value);
    }
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{title}</FormLabel>
      <RadioGroup
        aria-label={title}
        name={title}
        value={selectedValue}
        onChange={handleChange}
        row
        {...rest}
      >
        {data.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item}
            control={<Radio disabled={disableAll || item !== selectedValue} />}
            label={item}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
