import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { SERVER_CONFIG } from '../utils/config';
import toastify from '../components/Toastify';
import AuthContext from '../AuthContext';
import { setLocalStorageItem } from '../utils/localStorageUtils';

function LoginPage() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleLogin = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await axios.post(
        `${SERVER_CONFIG.SERVER_IP}/api/v1/user-login`,
        values
      );
      const { data } = response;
      if (data) {
        setLocalStorageItem('accessToken', data.jwtToken);
        setIsAuthenticated(true);
        toastify('SUCCESS', 'Logged in successfully!', 'alertmsg');
        navigate('/members-list', { replace: true });
      }
    } catch (error) {
      console.error('Login error:', error);
      toastify('ERROR', error.response.data.error, 'alertmsg');
      setFieldError('password', 'Invalid email or password');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='bg-gray-100 min-h-screen flex items-center justify-center'>
      <div className='bg-white p-8 rounded-lg shadow-md w-96'>
        <h2 className='text-2xl font-bold mb-4'>Login</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-6'>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Email address
                </label>
                <div className='mt-2'>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-xs'
                  />
                </div>
              </div>

              <div>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Password
                  </label>
                </div>
                <div className='mt-2'>
                  <Field
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2'
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='text-red-500 text-xs'
                  />
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                >
                  {isSubmitting ? 'Logging in...' : 'Login'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default LoginPage;
