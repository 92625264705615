import React from 'react';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const NumberInputField = ({
  title,
  isOptional = false,
  placeholder = null,
  onChangeText,
  isDataValid = true,
  value,
  onEndEditing = null,
  isMobile = false,
  disabled = false,
  ...rest
}) => {
  const handleChange = (e) => {
    onChangeText(e.target.value, true);
  };
  return (
    <TextField
      id='outlined-basic'
      error={!isDataValid}
      label={title}
      required={!isOptional}
      value={value}
      disabled
      variant='outlined'
      helperText={
        !isDataValid
          ? (value ?? '') !== ''
            ? placeholder
              ? placeholder
              : 'Enter a valid data'
            : 'Enter a valid data'
          : ''
      }
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={function (e) {
        if (onEndEditing !== null) {
          onEndEditing(e.target.value);
        }
      }}
      {...rest}
    />
  );
};

export const TextAreaInputField = ({
  title,
  style,
  onChangeText,
  value,
  disabled,
  isDataValid = true,
  isOptional = false,
  errorText,
}) => {
  const handleChange = (e) => {
    onChangeText(e.target.value, true);
  };
  return (
    <TextField
      style={style}
      label={title}
      variant='outlined'
      value={value}
      multiline
      InputProps={{
        inputComponent: TextareaAutosize,
        inputProps: {
          style: {
            resize: 'auto',
          },
        },
      }}
      onChange={handleChange}
      onFocus={handleChange}
      onBlur={function (e) {
        onChangeText(e.target.value);
      }}
    />
  );
};

export const SearchInputField = ({
  text,
  onSearchClick,
  placeholder = 'Search',
  autoFocus = false,
  handleChange,
}) => {
  const handleSearchAction = () => {
    onSearchClick(text);
  };

  return (
    <TextField
      id='outlined-basic'
      type='search'
      fullWidth
      style={{
        borderRadius: 5,
        border: 'none',
        boxShadow: '0px 1px 0px 1px #ccc',
        height: '55px',
      }}
      size='small'
      placeholder={placeholder}
      autoFocus={autoFocus}
      value={text}
      onChange={handleChange}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          handleSearchAction();
        }
      }}
      InputProps={{
        style: { height: '55px' },
        endAdornment: (
          <InputAdornment position='start'>
            <button
              className='inputSearchButton'
              onClick={() => {
                handleSearchAction();
              }}
            >
              <SearchIcon />
            </button>
          </InputAdornment>
        ),
      }}
    />
  );
};
